import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

const RandomCategory = () => {
  return (
    <div className="main-category-container">
      <Button className="mobile-random-action-btn" icon={<RightOutlined />}>
        Shop now
      </Button>
      <div className="category-img" />
      <div className="category-content">
        <span className="txt-new-title">BRANDS</span>
        <h1 className="txt-category-title">Casual Shirts</h1>
        <h4 className="txt-category-desc">
          Ease your way into your summer wishlist
        </h4>
        <Button className="random-action-btn" icon={<RightOutlined />}>
          Shop now
        </Button>
      </div>

      <style jsx="true">{`
        .main-category-container {
          display: flex;
          padding: 0 4rem;
          margin-top: 40px;
        }
        .category-img {
          background: url("./images/kids_gender_male.jpg");
          background-position: top;
          background-size: cover;
          flex-basis: 60%;
          height: 450px;
        }
        .category-content {
          margin: 40px;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          position: relative;
        }
        .txt-new-title {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 23px;
          color: #555555;
        }
        .txt-category-title {
          margin-bottom: 5px;
          margin-bottom: 16px;
          font-family: "DomaineSansText-Medium";
          color: #000000;
          font-size: 30px;
          line-height: 30px;
        }
        .txt-category-desc {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 25px;
        }
        .random-action-btn {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-around;
          align-items: center;
          margin-top: 24px;
          width: 254px;
          height: 48px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 16px DomaineSansText-Light;
        }
        .mobile-random-action-btn {
          display: none;
        }

        @media (max-width: 640px) {
          .main-category-container {
            padding: 0 16px;
            flex-direction: column-reverse;
            margin: 24px 0;
          }
          .category-img {
            flex-basis: auto;
            width: 100%;
            height: 248px;
          }
          .category-content {
            margin: 0;
            margin-bottom: 8px;
          }
          .txt-category-title {
            font-size: 16px;
            margin-bottom: 4px;
            line-height: normal;
            font-family: "DomaineSansText-Regular";
          }
          .txt-category-desc {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
          }
          .random-action-btn {
            display: none;
          }
          .mobile-random-action-btn {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-around;
            align-items: center;
            margin: 16px auto;
            width: 208px;
            height: 40px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 14px DomaineSansText-Light;
          }
          // .txt-new-title {
          //   font-size: 12px;
          // }
          .txt-category-desc {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default RandomCategory;
