import { useNavigate } from "react-router-dom";

const StyleBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="main-category-container">
      <div className="category-img" />
      <div className="category-content">
        <div className="category-content-title">Style With Substance</div>
        <p className="category-content-desc">
          This is not about trends, it’s about knowing what works, and wearing
          it well. Make a shift in pace, in wardrobe, in mindset. Think refined
          layers, relaxed tailoring, soft structure, and colour that carries.
          Wear pieces that move with you - thoughtful, versatile, and
          unmistakably yours. Great style does not ask for attention. It earns
          it
        </p>
        <p
          onClick={() =>
            navigate("/new-in/women/cid=2&name=new-in-women-products")
          }
          className="category-content-btn"
        >
          Shop New In
        </p>
      </div>

      <style jsx="true">{`
        .main-category-container {
          display: flex;
          padding: 30px 4rem 15px;
        }
        .category-img {
          background: url("https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1744185676/banner/iStock-656022052_xxlif7.jpg");
          background-position: top;
          background-size: cover;
          flex-basis: 60%;
          height: 450px;
        }
        .category-content {
          margin: 40px;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;
          position: relative;
        }
        .category-content-title {
          font: normal 600 30px DomaineSansText-Medium;
          line-height: 30px;
          color: #000000;
          margin-bottom: 15px;
        }
        .category-content-desc {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 25px;
          margin: 0;
        }
        .category-content-btn {
          font-size: 16px;
          font-family: DomaineSansText-Regular;
          text-decoration: underline;
        }

        @media (max-width: 640px) {
          .main-category-container {
            padding: 24px 16px;
            flex-direction: column-reverse;
            margin: 0;
          }
          .category-img {
            flex-basis: auto;
            width: 100%;
            height: 248px;
            margin-top: 20px;
          }
          .category-content {
            margin: 0;
            margin-bottom: 8px;
          }
          .txt-category-title {
            font-size: 16px;
            margin-bottom: 4px;
            line-height: normal;
            font-family: "DomaineSansText-Regular";
          }
          .category-content-desc {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
          }
          .category-content-title {
            font-size: 28px;
            margin-bottom: 10px;
          }
          .category-content-desc {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default StyleBanner;
