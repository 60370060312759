import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../../../redux/user";

import Banner from "./sections/BannerWomen";
import ShopBeauty from "./sections/ShopBeauty";
import ShopDesigner from "./sections/ShopDesignerWomen";
import Bestseller from "./sections/Bestseller";
import StyleBanner from "../womensPageWeb/sections/StyleBanner";

const WomensPageMobile = () => {
  const dispatch = useDispatch();

  const { setUserType } = bindActionCreators(userActionCreators, dispatch);

  useEffect(() => {
    setUserType("Women");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Banner />
      <ShopBeauty />
      <Bestseller />
      <StyleBanner />
      <ShopDesigner />
      {/* <Brands /> */}
      {/* <Line /> */}
      {/* <Line /> */}
      {/* <StylistChoice /> */}
      {/* <Verdict /> */}
      {/* <Line /> */}
    </div>
  );
};

export default WomensPageMobile;
