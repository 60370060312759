import { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const Brands = () => {
  const navigate = useNavigate();

  const { type } = useSelector((state) => state.user);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [activeSlide, setActiveSlide] = useState(0);

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);
    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  // const shopBrand = (id, slug) => {
  //   navigate(`/brands/id=${id}&name=${slug}`);
  // };

  const carouselSettings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // dots: false,
    infinite: false,
    afterChange: (current) => setActiveSlide(current),
  };

  const carouselRef = createRef();

  const brands = [
    {
      id: 181,
      title: "Donnie couture",
      imageUrl:"./images/kid_brand_1.jpg",
      slug: "Donnie-couture",
    },
    {
      id: 381,
      title: "Ero",
      imageUrl: "./images/kid_brand_2.jpg",
      slug: "Ero",
    },
    {
      id: 382,
      title: "Wickeed Stones",
      imageUrl:"./images/kid_brand_3.jpg",
      slug: "Wickeed-Stones",
    },
    {
      id: 5,
      title: "Kola Kuddus",
      imageUrl: "./images/kid_brand_4.jpg",
      slug: "Kola-Kuddus",
    },
  ];
  return (
    <div className="brands-container">
      <h1 className="brand-text">Bold, Audacious and Classic</h1>
      <h3 className="brand-desc">
      Exquisite and bespoke pieces for kids all year round
      </h3>
      <p className="txt-shop-now" onClick={() => shopNewIn()}>
        Shop Now
      </p>

      <div className="main-brands-box">
        <div className="cta-btn-box">
          <span className="txt-new-title">BETTER NOW THAN NEVER</span>
          <h1 className="txt-new-main">New Arrivals</h1>
          <h3 className="txt-new-desc">
          Discover new and rare treasures from N.A.C.K, Donnie's couture, Kola Kuddus and so many more.
          </h3>
          <Button className="btn-new-in" onClick={() => shopNewIn()}>
            Shop New In
          </Button>
        </div>
        {!isTabletOrMobile && <div className="cards-container">
          {brands.map((brand) => (
            <div className="brand-card" key={brand.imageUrl}>
              <img className="brand-image" alt="brand" src={brand.imageUrl} loading="lazy" />
              <h2 className="brand-title">{brand.title}</h2>
            </div>
          ))}
        </div>}
          {isTabletOrMobile && (
            <div>
              <Carousel {...carouselSettings} ref={carouselRef}>
                {brands.map((brand) => (
                  <div className="brand-card" key={brand.imageUrl}>
                    <img className="brand-image" alt="brand" src={brand.imageUrl} loading="lazy" />
                    <h2 className="brand-title">{brand.title}</h2>
                  </div>
                ))}
              
              </Carousel>

              <div className="process-icon-div-mobile">
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide > 0) carouselRef.current.prev();
                  }}
                >
                  <LeftOutlined className="process-icon" />
                </div>
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide !== 3) carouselRef.current.next();
                  }}
                >
                  <RightOutlined className="process-icon" />
                </div>
              </div>
              <div style={{textAlign:'center', marginTop:'40px'}}>
                <Button className="mobile-btn-new-in" onClick={() => shopNewIn()}>
                  Shop New In
                </Button>
              </div>

              
            </div>
          )}
      </div>
      <style jsx="true">{`
        .brands-container {
          background: #ffffff;
          margin-bottom: 40px;
        }

        .brand-text {
          text-align: center;
          font-family: "DomaineSansText-Medium";
          color: #000000;
          padding-top: 16px;
          font-size: 30px;
          line-height: 30px;
          margin: 8px auto;
        }
        .brand-desc {
          text-align: center;
          color: #000000;
          font-size: 16px;
          font-family: DomaineSansText-Light;
          line-height: 1.4;
          max-width: 1100px;
          margin: 8px auto 16px;
        }
        .txt-shop-now {
          cursor: pointer;
          font: normal 300 16px DomaineSansText-Medium;
          color: #000000;
          text-decoration: underline;
          text-align: center;
          margin-top: 7px;
        }
        .txt-shop-now:hover {
          text-decoration: none;
        }
        .main-brands-box {
          align-items: center;
          display: flex;
          justify-content: space-between;
          // margin-top: 40px;
          padding: 35px 4rem 0;
          gap: 8px;
        }
        .cta-btn-box {
          flex: 1.4;
          margin-top: 10px;
        }
        .txt-new-title {
          font: normal 300 16px DomaineSansText-Light;
          line-height: 23px;
          color: #555555;
        }
        .txt-new-main {
          margin-bottom: 16px;
          font-family: "DomaineSansText-Medium";
          color: #000000;
          padding-top: 16px;
          font-size: 30px;
          line-height: 30px;
        }
        .txt-new-desc {
          font: normal normal 16px DomaineSansText-Light;
          margin-bottom: 32px;
          line-height: normal;
        }
        .btn-new-in {
          width: 254px;
          height: 45px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 16px DomaineSansText-Light;
        }
        .cards-container {
          display: flex;
          flex: 4;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        .brand-card {
          width: 23%;
        }
        .brand-image {
          object-fit: cover;
          object-position: top;
          width: 100%;
          height: 345px;
        }
        .brand-title {
          font: normal normal 16px/23px DomaineSansText-Light;
          letter-spacing: 0.28px;
          color: #32353c;
          margin-top: 8px;
          text-align: center;
          text-transform: capitalize;
        }
        @media(max-width:640px){
          .brands-container{
            padding:0 16px;
          }
          .brand-text{
            font-size: 28px;
            text-align: center;
          }
          .brand-desc{
            font-size: 14px;
            text-align: center;
            line-height: 18px;
          }
          .txt-shop-now{
            margin-top: 12px;
            font-size: 14px;
            text-align: center;
          }
          .main-brands-box{
            display:block;
            padding: 0;
            margin-top: 24px;
          }
          .txt-new-title{
            font-size: 14px;
            font-family: DomaineSansText-Medium !important;
            font-weight: 600 !important;
            color: #000000;
          }
          .txt-new-main{
            font-size: 16px;
            padding-top: 0px;
            // margin-top: 8px;
            margin-bottom: 4px;
          }
          .txt-new-desc{
            font-size:14px;
            line-height: 20px;
            margin-bottom: 8px;
          }
          .btn-new-in{
            display:none
          }
          .process-icon-container {
            padding: 8px;
            border-radius: 50%;
            border: 1px solid #800000;
            width: 32px;
            height: 32px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .process-icon-div-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;
            margin: 16px 0;
            display:none
          }
          .process-icon {
            color: #800000;
          }
          .slick-slide div{
            width:100%
          }
          .brand-image {
            height: 248px;
            width: 100%;
            margin: auto;
            padding-right: 8px;
          }

          .mobile-btn-new-in {
            width: 208px;
            height: 40px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 14px DomaineSansText-Light;
          }
          .brand-title{
            font-family: 'DomaineSansText-Regular';
            font-size: 14px;
          }
          .ant-carousel .slick-dots-bottom{
             bottom:0;
             margin-right:0;
             margin-left:0
          }


        }
      `}</style>
    </div>
  );
};

export default Brands;
